(function ($) {
    "use strict";
    $(document).ready(function () {

        $(document).on("mouseover", ".main-header .navbar nav ul.main li.item ul li a",function(e) {
            let images = $(this).find("img");
            $(images).each(function () {
               if($(this).attr("data-hover") === "changePath")
               {
                   let currentPath = $(this).attr("src");
                   let newPath = currentPath.replace("normal", "hover");
                   $(this).attr("src", newPath);
               }
            });
        });

        $(document).on("mouseleave", ".main-header .navbar nav ul.main li.item ul li a",function(e) {
            let images = $(this).find("img");
            $(images).each(function () {
                if($(this).attr("data-hover") === "changePath")
                {
                    let currentPath = $(this).attr("src");
                    let newPath = currentPath.replace("hover", "normal");
                    $(this).attr("src", newPath);
                }
            });
        });

        //-----------------------------------------------------
        // BACK TO TOP
        //-----------------------------------------------------

        if ($('#back-to-top').length) {
            var scrollTrigger = $('body').height() / 2,
                backToTop = function () {
                    var scrollTop = $(window).scrollTop();
                    if (scrollTop > scrollTrigger) {
                        $('#back-to-top').addClass('show');
                        // $('.cc-window').fadeOut();
                    } else {
                        $('#back-to-top').removeClass('show');
                    }
                };
            backToTop();
            $(window).on('scroll', function () {
                backToTop();
            });
            $('#back-to-top').on('click', function (e) {
                e.preventDefault();
                $('html,body').animate({
                    scrollTop: 0
                }, 700);
            });
        }

        $('.cta-btn').on('click', function (e) {
            e.preventDefault();
            $('html,body').animate({
                scrollTop: 0
            }, 700);
        });

        // TOOLTIP
        $('[data-tooltip="tooltip"]').tooltip();

        // LOGOUT
        $('#logoutBtn').on('click', function () {
            $.ajax({
                type: 'get',
                url: "/billing/logout.php",
                data: {

                },
                success: function (data) {

                }
            });
        });

        //-----------------------------------------------------
        // WHMCS SESSION TOKEN
        //-----------------------------------------------------

        // INDOMINIO.getToken();
    });
    $.Indominio = function () { };
    $.Indominio.defaults = {
        token: null
    };
    $.Indominio.prototype = {
        InitEvents: function () {

        },
        getToken: function () {
            $.ajax({
                url: "/billing/index.php",
                type: 'POST',
                async: false,
                success: function (data) {
                    var token = $(data).find('input[name="token"]').val();
                    $.Indominio.defaults.token = token;
                    return token;
                }
            });
        }
    }
}(jQuery));

var INDOMINIO = new $.Indominio;


$("#logoutBtn").click(function (e) {
    e.preventDefault();

    $.ajax({
        type: 'GET',
        url: "/logout",
        data: {

        },
        success: function (data) {

            $.ajax({
                type: 'GET',
                url: "/billing/logout.php",
                data: {

                },
                success: function (data) {

                }
            });

            window.location.replace('/');
        }
    });
});